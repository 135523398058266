.card-hours {
    background: #f8f9fb;

    ion-item {
        --background:transparent !important;
        .content-header {
            .days {
                color: #4c5e64;
            }
        }
    }

    .accordion-content {
        .row {
            .day {
                div {
                    background-color: #20b4ac;
                    border: 1px solid #20b4ac;
                    color: white;
                }

                .unCheck {
                    background-color: transparent;
                    border: 1px solid #20b4ac;
                    color: #4c5e64;
                }

                label {
                    border: 1px solid #20b4ac;
                }
            }
        }

        ion-button {
            --background: transparent;
            --box-shadow: transparent;
            color: #ff4949;
            font-weight: 500;
        }
    }
}
