.paginator {
    ion-fab-button {
        --background: #4db1c9;
    }

    span {
        color: #bbbbbb;
    }

    .selected {
        color: white;
    }
}
