.gain {
    background-color: #20b4ac;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
}

.loss {
    background-color: #ff4949;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
}

.sell {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 62px !important;
    max-width: 62px !important;
    border: solid #ff4949 1px;
}

.buy {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 62px !important;
    max-width: 62px !important;
    border: solid #20b4ac 1px;
}


.completed-status {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 100px !important;
    max-width: 100px !important;
    border: solid #20b4ac 1px;
}

.process-status {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 100px !important;
    max-width: 100px !important;
    border: solid #FEBF02 1px;
}

.cancel-status {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 100px !important;
    max-width: 100px !important;
    border: solid #B5BCC6 1px;
}

.fail-status {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-block: 8px;
    text-align: center;
    min-width: 100px !important;
    max-width: 100px !important;
    border: solid #ff4949 1px;
}

.warning {
    padding-inline: 16px;
    padding-block: 6px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    border: solid 1px #FEBF02;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s linear;

    img {
        margin-right: 12px;
    }
}

.warning:hover {
    background-color: #222d41;
}

.warning-red {
    border-color: #ff4949;
}