.content {
    padding-bottom: 0;
}

.headbar {
    background-color: #1D2331;
    color: #ffffff;

    .right-content {
        span ion-icon {
            color: #ffffff;
        }

        .profile {
            .user {
                background: #94949480;
                transition: all 0.5s linear;
            }

            .user-hover:hover {
                background-color: #20b4ac;
            }

            .user:hover {
                box-shadow: 0px 0px 7px #449dac;
            }

            .icon-notification {

                ion-icon {
                    color: white;
                }

                .new-alert-point {
                    background: #4DB1C9;
                }
            }
        }
    }
}

.transparent {
    background-color: black !important;
}