.view-more {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    .description {
        font-weight: 500;
        margin-right: 8px;
    }

    ion-icon {
        color: #20b4ac;
        font-size: large;
    }
}

@media (max-width: 750px) {
    .view-more {
        .description {
            margin-right: 2px;
        }
    }
}