.card-hours {

    border-radius: 10px;
    padding: 16px 16px 16px 8px;
    margin-bottom: 16px;

    ion-item {
        --ripple-color: transparent;
        //--background-hover: transparent;

        .content-header {
            .hour {
                font-size: 36px;
                font-weight: 500;
                margin-block: 8px;
            }
        }
    }

    .accordion-content {
        margin-top: 30px;
        padding-left: 16px;

        .row {
            .day {
                display: flex;

                div {
                    cursor: pointer;
                    height: 25px;
                    width: 25px;
                    border: none;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 12px;
                    font-size: 10px;
                }

                .unCheck {
                    box-shadow: none;
                }

                label {
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                    margin: 0 2.5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }

        ion-button {
            font-weight: 400;
            margin-top: 16px;
            max-width: 100px;
            position: relative;
            left: -8px;
            text-align: start;
            --padding-start: 0px;

            ion-icon {
                font-size: 16px;
                margin-right: 8px;
            }
        }
    }
}