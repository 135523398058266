.paginator {
    margin-top: 16px;
    padding-bottom: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-fab-button {
        margin-inline: 6px;
        position: relative;
        height: 32px !important;
        width: 32px !important;
        box-shadow: none;

        ion-icon {
            font-size: 16px;
        }
    }

    span {
        margin-inline: 6px;
        font-size: 14px;
    }

    .selected {
        font-weight: 700;
    }
}