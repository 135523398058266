.buttons-type {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    .group-button-type {
        background-color: #0f121c;
        padding: 8px;
        border-radius: 5px;
        width: 324px;
        display: flex;
        justify-content: space-around;
        cursor: pointer;

        .button-selected {
            background-color: #171c2a;
        }

        .button-type-graph {
            display: flex;
            align-items: center;
            width: 140px;
            padding: 8px;
            justify-content: center;
            border-radius: 5px;

            img {
                width: 20px;
                margin-right: 16px;
            }
        }
    }
}

@media (min-width: 750px) {
    .buttons-type {
        .group-button-type {
            background-color: #252d3c;
        }
    }
}
