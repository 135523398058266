.table-default {
    color: #b5bcc6;

    .header {
        background: #1d2331 !important;
    }

    tr:hover {
        background: linear-gradient(90deg, #1c8883 10.89%, rgba(19, 21, 24, 0) 100%);
        color: white;
    }
    tr {
        td,
        th {
            ion-icon {
                color: rgb(96, 149, 234);
            }
        }
        .table-header {
            color: #bbbbbb;
        }
    }

    .odd-color {
        background: #18202d;
        border-bottom: solid #363e4b 1px;
    }

    .even-color {
        background: #172031;
        border-bottom: solid #363e4b 1px;
    }
}
