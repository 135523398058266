.alert-wrapper.sc-ion-alert-md {
    background: #F8F9FB;
}

.sc-ion-select-popover-md-h ion-list.sc-ion-select-popover-md {
    background: #F8F9FB;
}

.alert-radio-label,
[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color: var(--ion-color-step-850, #F8F9FB);
}

#ion-overlay-1 {

    ion-card-header {
        .button {
            color: #4c5e64;
        }
    }
   
    .footer {

        .button {
            color: #20b4ac;
        }
        .button-disabled {
            color: transparent;
        }
    }
}