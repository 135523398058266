app-plan-form {
    .end-content {
        justify-content: space-between;
        height: 100%;
    }
    .plan-form {
        margin-top: 25px;
        max-width: 50%;

        .plan-step {
            .plan-subtitle {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 8px;
            }

            .plan-description {
                margin-bottom: 32px;
                span {
                    margin-bottom: 5.6%;
                    text-align: center;
        
                    &.plan-user-name {
                        color: #20b4ac;
                        font-weight: 600;
                    }
                }
            }
            .select-label {
                margin-bottom: 32px;
            }

            .plan-input {

                .check-types {
                    flex-direction: column;
                    margin-top: 32px;

                    ion-item {
                        --min-height: 35px;
                        --padding-start: 0;
                        --border-color: transparent;
                        ion-checkbox {
                            margin: 0;
                        }
                        ion-label {
                            margin: 0 0 0 10px;
                        }
                    }

                    .check-item {
                        align-items: flex-start;
                        cursor: unset;

                        div {
                            position: relative;
                            top: -2px;
                            width: 100%;
                        }

                        .margin-none {
                            top: 0px;
                        }
                    }
                }
                .div-input{
                    max-width: 500px;

                    .input-container {
                        left: 0;
                        width: auto !important;
                        margin-inline: 4px;
                    }
                }
            }

            .plan-input-level {
                .check-types {
                    ion-item {
                        margin-bottom: 16px;
                        ion-checkbox {
                            position: absolute;
                            top: 3px;
                        }
                        ion-label {
                            margin: 0 0 0 28px;
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }
    .start-plan-button {
        .footer-buttons {
            margin-bottom: 32px;
            width: 84vw;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            column-gap: 16px;

            ion-button {
                width: 250px;
            }
        }

        .footer-end {
            justify-content: flex-end;
        }
    }
}

@media (max-width: 750px) {
    app-plan-form {
        .plan-form {
            margin-top: 0;
            max-width: 100%;
            overflow-y: scroll;
        }
        .start-plan-button {
            .footer-buttons {
                ion-button {
                    width: 100% !important;
                }
            }
        }
    }
}

ion-modal {
    app-plan-form {
        padding: 32px;

        .header-buttons {
            display: flex;
            justify-content: space-between;
    
            .title {
                display: block;
                margin-bottom: 16px;
            }
    
            ion-icon {
                font-size: 24px;
                cursor: pointer;
            }
        }

        .end-content {
            justify-content: space-between;
        }

        .plan-form {
            max-width: 100%;
        }
        
        .start-plan-button {
            .footer-buttons {
                width: 100%;
            }
        }
    }
}