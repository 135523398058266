.row-subtitle {
    .item-subtitle {
        .description {
            color: #b5bcc6 !important;
            font-weight: 500 !important;
        }

        .description-legend {
            color: #131821 !important;
            font-weight: 600 !important;
        }
    }
}


@media (max-width: 750px) {
    .row-subtitle {
        .item-subtitle {
            .description {
                color: #4c5e64 !important;
                font-weight: 500 !important;
            }
        }
    }
}