

    .slide-tutorial-report {
        width: 100%;
        height: calc(100% - 54px);

        .slide-item {
            width: 100%;
            overflow-y: scroll;

            .slide-container {
                width: 100%;
                height: 100%;

                .title-slide{
                    width: 100%;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 500;   
                    margin-bottom: 14px; 
                }
    
                .img-slide{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px; 
    
                    img {
                        width: 98%;
                        border-radius: 5px;
                    }
                }
    
                .content-slide {
                    font-size: 14px;
                    width: 100%;
                    text-align: start;
                    padding-bottom: 10px;
    
                    ul {
                        position: relative;
                        left: -40px;
                        list-style: none;
    
                        li {
                            margin-bottom: 8px;
                            line-height: 20px;
                        }
    
                        .info-tutorial {
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }

    .footer-operation-start {
        justify-content: flex-end !important;
    }
    .footer-operation {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ion-button {
            max-width: 150px;
        }

        .button-navigate-tutorial  {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            border-radius: 6px;
            padding-inline: 12px;
            padding-block:8px;
            cursor: pointer;

            ion-icon {
                font-size: 18px;
            }
        }

        .icon-right {

            ion-icon {
                margin-left: 6px;
            }
        }

        .icon-left {

            position: relative;
            left: -8px;
            ion-icon {
                margin-right: 6px;
            }
        }

        .button-finish {
            width: 170px;
        }
    }