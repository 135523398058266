.item-statistic {
    display:flex;
    justify-content: space-between;
    padding-block: 16px;
    border-bottom: 1px solid rgba($color: #477299, $alpha: 0.13);

    .icon {
        margin-right: 8px;
    }
    .total {
        font-weight: 600;
    }
}