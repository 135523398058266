

.alert-format {

    .alert-message {
        text-align: start;
        margin-top: 20px;
    }
    
    .alert-button-group {
        padding-top: -20px;
        display: flex;
        justify-content: center;

        button {
            width: 90%;
            left: 5px;
            text-align: center;
            border-radius: 7px;

            .span {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }

        .alert-button-inner {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    .alert-radio-group.sc-ion-alert-md {
        border: none !important;
    }
}

.alert-default{
    color: white;
    transition: all 0,5s;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 500px;
    overflow: hidden;

    .alert-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        width: 100%;
        padding: 16px;

        .icon-status {
            font-size: 24px;
        }

        span {
            width: 100%;
            text-align: start;
        }

        .icon-close {
            font-size: 20px;
            font-weight: bold;
            color: rgba($color: #fff, $alpha: 0.6);
            cursor: pointer;
        }
        
        .icon-close:hover {
            color: #fff;
            transition: all 1s;
        }
    }

    ion-progress-bar {
        --background: transparent;
    }
}

.alert-default-error {
    background-color: #ff4949;

    ion-progress-bar {
        --background: transparent;
        --progress-background: #fe6767;
    }
}

.alert-default-success {
    background-color: #20b465;

    ion-progress-bar {
        --background: transparent;
        --progress-background: #45cf86;
    }
}

.alert-default-warning {
    background-color: #f0a43b;

    ion-progress-bar {
        --background: transparent;
        --progress-background: #ffc573;
    }
}

@media (max-width: 750px) { 
    .icon-status {
        display: none;
    }
    .alert-default {
        width: calc(100% - 64px);
    }

    .icon-close {
        font-size: 32px !important;
    }
}