.alert-wrapper.sc-ion-alert-md {
    background: white;
}

.sc-ion-select-popover-md-h ion-list.sc-ion-select-popover-md {
    background: #172031;

    ion-label {
        color: white;
    }
}

.alert-radio-label,
[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color: var(--ion-color-step-850, #ccc);
}

#ion-overlay-1 {

    ion-card {
        background: #11615d;
        color: #b5bcc6;

        ion-card-header {
            ion-card-title {
                color: white !important;
            }
            .button {
                color: white;
            }
        }
    }
   
    .footer {

        .button {
            color:white;
        }
        .button-disabled {
            color: transparent;
        }
    }
}