.table-default {
    padding-top: 0;
    width: 100%;

    border-radius: 10px 10px 10px 10px;

    .header {
        border-top-right-radius: 10px;
        border-radius: 10px 10px 10px 10px;
    }

    tr:hover {
        cursor: pointer;
    }

    tr {
        .center {
            display: flex;
            justify-content: center;
        }

        .text-center {
            text-align: center;
        }

        .text-start {
            padding-left: 12px !important;
            text-align: start !important;
        }
        td,
        th {
            padding-block: 8px !important;
            font-weight: normal;

            ion-icon {
                font-size: 20px;
                cursor: pointer;
            }
        }

        .value {
            font-weight: 500;
        }

        .header-with-tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 6px;
        }

        .action {
            width: 48px;
        }

        .table-header {
            font-weight: 700;
            text-align: center;
            cursor: auto;
        }
    }

    .asset {
        font-weight: 600;
    }

    img {
        cursor: pointer;
    }
}
