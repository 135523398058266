.div-input {
    ion-item {
        --highlight-color-invalid: #4c5e64;
    }
}

.item-input,
.item-select {
    --border-color: #4c5e64;
    --highlight-color-focused: #20b4ac;
    --highlight-color-valid: #20b4ac;
}

ion-input {
    --placeholder-color: #5d6268 !important ;
}

.placeholder {
    --placeholder-color: #5d6268 !important ;
}

//Text área (tela de feedback)
ion-textarea {
    border: none;
    --placeholder-color: #5d6268 !important ;
}

.ion-textarea {
    border:1px solid #20B4AC;
}

.native-input[disabled].sc-ion-input-md {
    opacity: 0.7 !important;
  }