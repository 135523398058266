.segments {
    ion-segment {
        justify-content: flex-start;
        background: #0F121C;
        border-radius: 9px;
        padding: 6px;
        max-width: 600px;

        ion-segment-button {
            max-width: 220px;
            --checkbox-background: #171C2A;
            --border-radius: 9px;
            --border-style: none;
            --indicator-height: 0px;
            --color-checked: white;
            --color: #353A49;
            text-transform: none;
            font-weight: 500;
            --ripple-color: transparent;

            --background-hover-opacity: 0;
        }
    }
}