@media (min-width: 750px) {
    .side-menu {
        --width: 417px;
        --height: 86vh;
        --border-radius: 10px;
        right: calc(-100% + (417px + 10vw));
        bottom: calc(-10vh + 32px);
    }        
}

.side-menu {
    .side-menu-modal{
        height: 100%;
        background: #172031;

        .header-side {
            display: flex;
            justify-content: flex-end;
            padding: 16px 32px;
            background: #131821;
            max-height: 65px;

            ion-button {
                width: 30px;
                min-height: 30px;
                height: 30px;
                --padding-start: 0;
                --padding-end: 0;
                --background: #172031;
            }
        }

        .side-menu-content {
            width: 100%;
            height: calc(100% - (213px));
            padding: 32px 32px 0;
            margin-bottom: 32px;
            overflow-y: scroll;

            .card-content-menu {
                //min-height: 130px;
                background: #131821;
                border-radius: 10px;
                margin-bottom: 32px;
                padding: 16px;
                color: white;

                .over-text {
                    margin-top: 16px;
                }

                .card-content-header {
                    display: flex;
                    justify-content: space-between;

                    .card-content-title {
                        width: calc(100% - 100px);
                    }
                }

                .card-content-body {
                    height: 86px;
                    margin-top: 16px;
                    padding: 16px;
                    background: #202D46;
                    border-radius: 10px;
                    cursor: pointer;
                    border: solid #202D46 1px;

                    .text-content {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                        font-weight: 500;

                        p:first-child {
                            margin-block-start: 0;
                        }
                    }
                }

                .card-content-body:hover {
                    border: solid #449dac 1px;
                    box-shadow: 0px 0px 7px #449dac;
                    transition: all 0.3s linear;
                }

                .card-content-shadow {
                    height: 5px;
                    margin: 0 8px;
                    background: #30405a;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            .card-content-menu:last-child {
                margin-bottom: 0;
            }
        }

        .footer-side {
            display: flex;
            justify-content: space-between;
            padding: 0 32px;
            max-height: 84px;

            ion-button {
                width: 75px;
                height: 75px;
                --background: #131821;
                border: solid #131821 1px;
                border-radius: 5px;

                .button-footer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    ion-icon {
                        font-size: 20px;
                    }

                    img {
                        width: 20px;
                    }

                    span {
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }

            ion-button:hover {
                border: solid #449dac 1px;
                box-shadow: 0px 0px 7px #449dac;
                transition: all 0.3s linear;
            }
        }
    }
}