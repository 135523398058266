.add-hour-card {
    background: #F8F9FB;

    .add-hour {

        .icon-clock {
            span {
                color: #20b4ac !important;
            }
        }

        .btn-add {
            --background: #20b4ac;
        }
    }

    .description-hour {
        .text-hour {
            color: #4c5e64;
        }
    }
}

.backdrop-add-hour {
    background: #1315188f;

    .modal {
        background: #F8F9FB;
        color: #131821;

        .modal-content {

            .input-description {
                color: #6b797e;
                font-weight: 600;
            }

            ion-item {
                color: #4c5e64;
            }
        }

        .btn-cancel {
            --background: transparent;
            color: #20b4ac;
        }
    }
}
