.over-text {
  color: #b5bcc6;
}

.component-title {
  .session-title {
    color: #d9dde2;

    .desc-title {
      color: #4c5e64;
    }
  }
}

.tip-title{
  color: #d9dde2;
}

.recovery {
  a {
    color: white;
  }
}