.fc {
    .fc-header-toolbar {
        .fc-toolbar-chunk {
            .fc-toolbar-title {
                font-size: 18px;
            }
            .fc-toolbar-title::first-letter {
                text-transform: uppercase !important;
            }
        }
    }

    table {
        cursor: pointer !important;

        thead {
            tr {
                .fc-col-header-cell-cushion::first-letter {
                    text-transform: uppercase !important;
                }
            }
        }
    }
}