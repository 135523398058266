ion-menu {
    --background: #18202D;

    a {
        color: #ffffff;
    }

    .menu {
        .list-menu {
        
            ion-list {
                background: transparent;
                
                .header {
                    background-color: transparent;
                }

                ion-menu-toggle {

                    ion-item {
                        a:hover {
                            border: solid #0d5f5b 1px;
                        }
                    }
                }

                ion-item {
                    --background: transparent;
                    
                    .active {
                        border: solid #20b4ac 1px;
                        box-shadow: 0px 0px 4px #449dac;
                    }
                    
                    .active:hover {
                        border: solid #27dfd5 1px;
                    }
                }
            }
        }
    }

    .footer-menu {
        ion-item {
            --background: transparent;
            color: #ffffff;
        }
    }

    @media (min-width: 750px){

        .footer-menu {
            ion-item {
                --background: transparent;
                color: #ffffff;
            }
        }    
    }
}
