.footer-links {
    display: flex;
    justify-content: space-around;
    height: 54px;
    padding-inline: 16px;
    align-items: center;

    .selected {
        width: 32px;
    }
}

.footer-links-ios {
    min-height: 54px;
    height: calc(calc(env(safe-area-inset-bottom) - 20px) + 54px);
    padding-bottom: calc(env(safe-area-inset-bottom) - 20px);
}
