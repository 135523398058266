.fc {
    .fc-header-toolbar {
        .fc-toolbar-chunk {
            .fc-toolbar-title {
                color: #131821;
            }

            .fc-button-group {
                button {
                    background-color: #20b4ac;
                    border-color: #20b4ac;
                }
            }
        }
    }

    table {
        border-color: #d0d0d0 !important;

        tr {
            th,
            td {
                border-color: #d0d0d0 !important;
            }
        }

        thead {
            tr {
                .fc-col-header-cell-cushion {
                    color: #8c929d !important;
                }
            }
        }
    }

    .fc-daygrid-day-number {
        color: #131821;
    }

    .fc-daygrid-day {
        background-color: transparent !important;
    }
}