.over-text {
  color: #4c5e64;
}

.component-title {
  .session-title {
    color: #131821 !important;

    .desc-title {
      color: #4c5e64;
    }
  }
}

//Título das páginas
.title {
  font-weight: 500;
}

//subtítulo das páginas
.subtitle {
  color: #4c5e64;
}

.tip-title {
  color: #131821;
}
