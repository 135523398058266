.add-hour-card {
    background: #18202d;

    .add-hour {

        .icon-clock {
            span {
                color: white;
            }
        }

        .btn-add {
            --background: none;
        }
    }

    .description-hour {
        .text-hour {
            color: #bfbfbf;
        }
    }
}

.backdrop-add-hour {
    background: #1315188f;

    .modal {
        background: #18202D;
        color: white;

        .modal-content {

            .input-description {
                color: #B5BCC6;
            }

            ion-item {
                color: white;
            }
        }

        .btn-cancel {
            --background: transparent;
        }
    }
}
