.alert-format {

    .alert-wrapper.sc-ion-alert-md {
        background: #eaebf0;
    }

    .alert-message {
        color: #131821;
    }
    
    .alert-button-group {

        button {

            .span {
                color: #131821;
            }
        }

        .alert-button-inner {
            color: #20b4ac;
        }
    }

    .alert-radio-group.sc-ion-alert-md {

        .alert-radio-label.sc-ion-alert-md {
            color: var(--ion-color-step-850, #131821);
        }
    }
}

.delete {
    .alert-button-group {
        --ripple-color: gray;
        
        .alert-button-inner {
            color: #ff4949;
        }

        .cancel-button {
            color: #20b4ac;
            
            .alert-button-inner {
                color: #131821;
            }
        }
    }
}