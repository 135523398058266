.check-types {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    margin-left: 2px;
    
    .check-item {
        display: flex;
        margin-right: 16px;
        margin-bottom: 16px;
        align-items: center;

        cursor: pointer;

        ion-checkbox {
            margin-right: 8px;
        }
    }

    .check-all-operation {
        min-width: 134px;
    }
}