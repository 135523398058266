.popover-button {
    ion-item {
        font-weight: 500;
        cursor: pointer;
    }
    
    ion-item:hover {
        --background: #20b4ac;
        color: #fff;
        font-weight: 600;
        box-shadow: 0px 0px 5px 2px rgba(32, 180, 172, 0.25);
    }
}
