.table-default {
    padding-top: 16px !important;

    tr:hover {
        background: #F8F9FB;
    }
    tr {
        background-color: #FFFFFF;
        td,
        th {
            ion-icon {
                color: rgb(96, 149, 234);
            }
        }

        border-bottom: solid #d0d0d0 1px;
    }

    .header {
        background: #F8F9FB;
    }
}
