.card-graph {
    background: #172031;

    .card-title {
        color: white;
    }

    .item {
        color: #b5bcc6;
    }
}

@media (min-width: 750px) {
    .card-graph {
        border: solid #172031 1px;
    }
}

.card-graph-inline {
    border: 1px solid rgba(88, 168, 247, 0.3);
    background: transparent;
}

.card-graph-login {
    background: rgba(23, 32, 49, 0.6);
}

.background-last-operations {
    background-color: #1d2331;
}

.item-statistic {
    background-color: #172031;

    .title-statistic {
        color: #b5bcc6;
    }
}

.card-tips {
    background: #0f121c;
}

.card-hover:hover {
    border: solid #449dac 1px;
    box-shadow: 0px 0px 7px #449dac;
    transition: all 0.5s linear;
}
