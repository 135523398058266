ion-calendar {
    background-color: #F8F9FB !important;

    .days-btn {
        p {
            color: #363E4B !important;
        }
    }

    .last-month-day, .next-month-day {
        p {
            color: #B5BCC6 !important;
        }
    }

    .on-selected,
    .endSelection {
        p {
            color: white !important;
        }
    }

    .between {
        .days-btn {
            p {
                color: white !important;
            }
        }
    }

    ion-toolbar {
        --background: #F8F9FB !important;

        .week-title {
            background-color: transparent !important;            
            color: #999898 !important;
            font-weight: 600 !important;
        }
    }
}
