.btn-card-tip {
    background-color: #20b4ac;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 32px;
    width: 44px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s linear;

    img {
        width: 11px !important;
        margin: 0px !important;
    }
}

.btn-card-tip:hover {
    
    background-color: #1ea19b;
}
