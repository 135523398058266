.list-messages-feedbacks {
    ion-row {
        ion-col {
            .circle-user {
                background-color: #4db1c9;
                ion-icon {
                    color: white;
                }
            }

            .circle-my-user {
                background-color: #94949480;
            }

            .support-message {
                background-color: #F8F9FB;
            }

            .my-message {
                background-color: #F8F9FB;
            }
        }
    }
}
