.card-hours {
    background: #172031;

    ion-item {
        --background:transparent !important;
        .content-header {

            .days {
                color: #bfbfbf;
            }
        }
    }

    .accordion-content {

        .row {
            .day {

                div {
                    box-shadow: 0px 0px 5px 2px rgba(32, 180, 172, 0.25);
                    background-color: #20b4ac;
                }

                .unCheck {
                    background-color: transparent;
                    box-shadow: none;
                    border: 1px solid white;
                }

                label {
                    border: 1px solid white;
                }
            }
        }

        ion-button {
            --background: transparent;
            --box-shadow: transparent;
        }
    }
}