.list-messages-feedbacks {
    width: 100%;
    height: calc(100% - 400px);

    ion-row {
        ion-col {
            margin-bottom: 24px;
            display: flex;
            column-gap: 22px;

            .circle-user {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                height: 50px;
                width: 55px;
                border-radius: 100%;
                aspect-ratio: 1;
                .perfil {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }

            .date-response {
                width: 100%;
                text-align: end;
                margin-bottom: 16px;
            }

            .support-message {
                border-radius: 8px;
                width: 100%;
                padding: 16px;
                display: flex;
                flex-direction: column;
            }

            .my-message {
                border-radius: 8px;
                width: 100%;
                padding: 16px;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .list-messages-feedbacks {
        ion-row {
            ion-col {
                .circle-user {
                    .perfil {
                        object-fit: initial;
                    }
                }
            }
        }
    }
}


@media (max-width: 750px) {
    .list-messages-feedbacks {
    
        ion-row {
            ion-col {
                margin-bottom: 12px;
                column-gap: 8px;
    
                .circle-user {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    height: 32px;
                    width: 36px;
                    border-radius: 100%;
                    aspect-ratio: 1;
                }
            }
        }
    }
}