

/* Custom font-family  */
@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url("../fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 100;
    src: url("../fonts/Roboto/Roboto-Thin.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: url("../fonts/Roboto/Roboto-bold.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url("../fonts/Roboto/Roboto-Medium.ttf");
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  font-size: 14px;
}

ion-text {
    font-size: 14px;
}

ion-item {
    font-size: 14px;
}

.color-primary {
    color: #20b4ac;
}

.color-secondary {
    color: #4db1c9;
}

.color-black {
    color: black;
}

.color-danger {
    color: #ff4949;
}

.color-tertiary {
    color: #FEBF02;
}

.color-success {
    color: #19C25A;
}

//Título das páginas
.title {
    font-size: 24px;
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-start;
}

//subtítulo das páginas
.subtitle{
    margin-bottom: 15px;
    width: 100%;
    justify-content: flex-start;
    color: #B5BCC6;
}

// Fonte da mensagem de validação dos formulários
.error-form {
    color: #fe2a46 !important;
    font-weight: 600 !important;
    font-size: 12px;
}

.center-text {
    text-align: center;
}

.component-title {
    display: flex;
    align-items: center;

    .item-title {
        max-width: 38px;
    }

    .session-title {
        font-size: 16px;
        font-weight: bold;
        position: relative;

        .desc-title {
            font-size: 12px;
        }
    }
}



@media (max-width: 750px) {
    .component-title {
    
        .session-title {
            left: -16px;
        }
    }
    
}