.tutorial-popover {
    --background: transparent;
    --box-shadow: none;
    --width: 300px;

    .card-tutorial {
        background: #11615d;
        color: #b5bcc6;

        ion-card-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;

            ion-card-title {
                color: white !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            ion-button {
                color: white;
                --padding-start: 5px;
                --padding-end: 5px;
                --padding-top: 5px;
                --padding-bottom: 5px;
                margin: 0 -8px 0 0;
            }
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px 8px;

            .button-off {
                color: transparent;
            }
        }
    }
}