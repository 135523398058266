.sc-ion-select-popover-md-h ion-list.sc-ion-select-popover-md {
    ion-label {
        margin-left: 16px;
    }
}

.sc-ion-select-popover-md-h.sc-ion-select-popover-md-s {
    max-height: 300px;
}

.default-popover {
    --max-width: 350px !important;
    --min-width: 350px !important;
}

.month-popover{
    --max-width: 250px !important;
    --min-width: 250px !important;
}