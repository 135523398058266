.sell {
    color: #131821;
}

.buy {
    color: #131821;
}

.warning {
    background-color: #FEBF02;

    .color-tertiary {
        color: white !important;
    }
}

.warning-red {
    background-color: #ff4949;

    .color-danger {
        color: white !important;
    }
}

.completed-status {
    color: #131821;
}

.process-status {
    color: #131821;
}

.cancel-status {
    color: #131821;
}

.fail-status {
    color: #131821;
}

.quant-message-not-read {
    background-color: #4db1c9
}