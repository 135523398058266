.tooltip-table {

    .tooltip-table-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #20b4ac;
        color: #fff;
        font-size: 12px;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .tooltip-table-card {
        position: absolute;
        background-color: #11615d !important;
        color: #fff;
        text-align: start;
        padding: 16px;
        font-weight: normal;
        display: none;
        z-index: 100;
        border: none !important;

        div span {
            font-weight: 600;
        }
    }
}

.tooltip-table:hover {
    .tooltip-table-card {
        display: block;
    }
}