.div-input {
    margin-bottom: 32px;

    span {
        color: #fff;
    }

    .color-primary {
        color: #20b4ac;
    }

    .color-danger {
        color: #ff4949;
    }

    ion-select::part(text) {
        white-space: normal !important;
    }

    ion-select::part(icon) {
        margin-inline: unset !important;
        margin-top: unset !important;
        margin-bottom: unset !important; 
        right: 16px;
        position: absolute;
    }
}

ion-input {
    --padding-start: 12px !important;
    --padding-top: 0px !important;
    --border-radius: 10px !important;
    --border-color: transparent !important;
    --placeholder-color: #B5BCC6 !important ;
    --color: #B5BCC6 !important ;
    --highlight-color-focused: #B5BCC6 !important;
    --highlight-color-invalid: #fe2a46 !important;
    --highlight-color-valid: #449dac !important;
}


//Remove os ícones arrow dos inputs do tipo number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

ion-select {
    --padding-inline-start: 8px !important;
    --padding-inline-end: 8px !important;
    --border-radius: 10px !important;
    --border-color: transparent !important;
    --placeholder-color: #B5BCC6 !important ;
    --highlight-color-focused: #B5BCC6 !important;
    --highlight-color-invalid: #fe2a46 !important;
    --highlight-color-valid: #449dac !important;
    width: 100% !important;
    color: #B5BCC6;
    min-height: 48px !important;
    height: 45px !important;
}

ion-item.sc-ion-select-popover-md {
    --background: transparent
}
// .item-input,
// .item-select {
// }

// .native-input.sc-ion-input-md {
//     padding-bottom: 6px;
//     padding-left: 12px;
//     padding-right: 12px;
// }

//Utilizado quando um input recebe o foco


.input-container {
    position: relative;
    left: -7px;
    width: 100% !important;
    height: 50px;
    transition: all 0.2s linear;
    border-radius: 10px;
    border: solid 1px #374151;
    margin: 7px;
    margin-top: 12px;

    .view-password {
        cursor: pointer;
        position: absolute;
        right: 16px;
        bottom: 12px;
        z-index: 10;
    }
}
.input-container-textarea {
    height: unset;
}

.native-input.sc-ion-input-md {
    padding-bottom: 6px;
    padding-right: 0px;
}

.input-container-selected {
    border-color: #449dac;
    box-shadow: 0px 0px 7px #449dac !important;
}

.input-container-invalid {
    border-color: #fe2a46;
    box-shadow: 0px 0px 7px #fe2a46 !important;
}

ion-textarea{
    --padding-start: 12px !important;
    --padding-top: 12px !important;
    --border-radius: 10px !important;
    --border-color: transparent !important;
    --placeholder-color: #B5BCC6 !important ;
    --color: #B5BCC6 !important ;
    --highlight-color-focused: #B5BCC6 !important;
    --highlight-color-invalid: #fe2a46 !important;
    --highlight-color-valid: #449dac !important;
}
// //Text área (tela de feedback)
// ion-textarea {
//     padding: 5px 10px;
// }

// .sc-ion-textarea-md-h {
//     --padding-top: 0px;
// }

// .ion-textarea {
//     outline: none !important;
// }
