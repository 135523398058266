.file-drop-zone {
    width: 100%;
    height: 100%;
    padding: 2rem;
    text-align: center;
    border: solid 1px #4f5153;
    position: relative;
    margin-top: 32px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        cursor: pointer;
        left: 0;
    }

    img {
        opacity: 0.5;
        width: 100px;
        transition: all 0.3s linear;
        margin-bottom: 16px;
    }

    span {
        color: #b5bcc6;
        transition: all 0.3s linear;
    }

    .text-drag {
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 8px;
    }
}

.file-drop-zone-active {
    border-color: #20b4ac !important;
    box-shadow: 0px 0px 7px #449dac;

    img {
        opacity: 1;
    }
}

.file-drop-zone:hover {
    border-color: #20b4ac;
    box-shadow: 0px 0px 7px #449dac;

    img {
        opacity: 1;
    }
}



.file-drop-zone-with-image {
    justify-content: center;

    .img-operation {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }

    .section-texts {
        padding: 16px;
        border-radius: 8px;
    }

    .footer-buttons-drop {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        bottom: 0px;
        z-index: 10;

        .div-button {
            width: 100%;
            height: 48px;
            background-color: rgba($color: #000000, $alpha: 0.6);
            transition: all 0.3s linear;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
        }
        @media (min-width: 750px) {
            .div-button:hover {
                background-color: #20b4ac;
            }
        }

        .button-left {
            border-radius: 0px 0px 0px 10px;
        }

        .button-right {
            border-radius: 0px 0px 10px 0px;
        }
    }
}