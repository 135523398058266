.card-graph {

    border-radius: 8px;
    padding: 24px;

    .card-title {
        font-size: 16px;
        font-weight: 500;
    }

    .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        font-weight: 400;

        div {
            width: auto !important;
        }
            
        .icon {
            margin-right: 8px;
        }

        .total {
            font-weight: 600;
        }
    }

    .item-last {
        margin-bottom: 0px;
    }
}

.card-graph-inline {
    background: transparent;
}