ion-button {
  color: white;
  --border-radius: 5px;
  text-transform: none;
  min-height: 40px;
  font-size: 14px;
  --background-activated: linear-gradient(90deg, #446669 29.94%, #3d535e 61.89%);
}

.primary-gradient {
  --background: linear-gradient(90deg, #1d504d 29.94%, #173a3a 61.89%);
}

.outline-button {
  --background: transparent;
  --border-color: rgba(white, 0.8);
  --border-width: 1.5px;
  --border-style: solid;
}

.solid-button {
  --background: #20b4ac;
}

.button-user-data {
  --background: #374151;
}

ion-fab-button {
  border-radius: 100%;
  box-shadow: 0px 0px 5px 2px rgba(32, 180, 172, 0.25);
  background-color: #20b4ac;
}


.fab-anchor-mobile {
  bottom: 150px !important;
  right: 40px!important;
  
  ion-fab-button {
      width: 40px;
      height: 40px;
  }
}