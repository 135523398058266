ion-menu {
    --background: #F8F9FB;

    a {
        color: #131821;
    }

    .menu {
        .list-menu {
        
            ion-list {
                background: transparent;
                
                .header {
                    background-color: transparent;
                }

                ion-menu-toggle {

                    ion-item {
                        a:hover {
                            border: solid #0d5f5b 1px;
                        }
                    }
                }

                ion-item {
                    --background: transparent !important;
                    
                    .active {
                        border: solid #20b4ac 1px;
                        box-shadow: 0px 0px 7px #449dac;
                    }
                }
            }
        }
    }

    .footer-menu {
        ion-item {
            --background: transparent;
            color: #ffffff;
        }
    }

    @media (min-width: 750px){

        .footer-menu {
            ion-item {
                --background: transparent;
                color: #ffffff;
            }
        }    
    }
}
