.fc {
    .fc-header-toolbar {
        .fc-toolbar-chunk {
            .fc-toolbar-title {
                color: #b5bcc6;
            }
        }
    }

    table {
        border-color: #363e4b !important;

        tr {
            th,
            td {
                border-color: #363e4b !important;
            }
        }

        thead {
            tr {
                .fc-col-header-cell-cushion {
                    color: #8c929d !important;
                }
            }
        }
    }

    .fc-daygrid-day-number {
        color: white;
    }

    .fc-daygrid-day {
        background-color: transparent !important;
    }
}