/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "animate.css";

/* Core CSS requi#fe2a46 for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "assets/style/alert.scss";
@import "assets/style/card-graph.scss";
@import "assets/style/input-form.scss";
@import "assets/style/fonts-default.scss";
@import "assets/style/buttons.scss";
@import "assets/style/popover.scss";
@import "assets/style/ion-calendar.scss";
@import "assets/style/field-filter-day.scss";
@import "assets/style/bedge.scss";
@import "assets/style/table.scss";
@import "assets/style/segment.scss";
@import "assets/style/button-card-tips.scss";
@import "assets/style/item-statistic.scss";
@import "assets/style/button-type-segment.scss";
@import "assets/style/view-more.scss";
@import "assets/style/paginator.scss";
@import "assets/style/slide-tutorial-report.scss";
@import "assets/style/check-types.scss";
@import "assets/style/full-calendar.scss";
@import "assets/style/card-add-hour.scss";
@import "assets/style/card-hour.scss";
@import "assets/style/footerbar.scss";
@import "assets/style/info-card-graph.scss";
@import "assets/style/feedback-messages.scss";
@import "assets/style/file-drop-zone.scss";
@import "assets/style/card-banner.scss";
@import "assets/style/tooltip.scss";
@import "assets/style/plan-progress.scss";
@import "assets/style/plan-form.scss";
@import "assets/style/ckeditor.scss";
@import "assets/style/side-menu.scss";
@import "assets/style/tutorial-popover.scss";

@media (max-width: 750px) {
    .content {
        padding: 32px;
        height: 100%;

        ion-button {
            width: 100%;
        }
    }

    .title {
        display: none;
    }

    .card-graph {
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 16px;
    }
}

@media (min-width: 750px) {
    .content {
        padding: 32px 8vw 32px 8vw;
        height: 100%;

        ion-button {
            width: 100%;
            max-width: 400px;
        }
    }

    .content-web-skeleton {
        padding: 32px 14vw 32px 14vw;
        height: 100%;
    }

    .end-content {
        overflow-x: hidden;
    }

    .tip-modal {
        --border-radius: 10px !important;
    }

    .finish-modal {
        --width: 740px;
    }
}

.end-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow-y: scroll;
}

@media (min-width: 1600px) {
    .content {
        padding: 32px 8vw 32px 8vw;
        height: 100%;
    }
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

//Personaliza o scrollbar
::-webkit-scrollbar {
    display: none;
    background: #f5f5f51e;
    width: 0.3vw;
}

::-webkit-scrollbar-track {
    display: none;
    background: #f5f5f51e;
    width: 0.3vw;
}

::-webkit-scrollbar-thumb {
    background: #3c3c3c;
}