.add-hour-card {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;

    .add-hour {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: -6px;

        .icon-clock {
            display: flex;
            align-items: center;
            width: 50px;
            height: 39px;
            border-radius: 5px;

            span {
                width: 100%;
                text-align: center;
            }
        }

        .btn-add {
            --box-shadow: none;
            min-height: 41px;
            width: 125px;

            ion-icon {
                margin-right: 8px;
                --padding-start: 0px;
                --padding-end: 0px;
            }
        }
    }

    .description-hour {
        padding-top: 20px;

        .title-hour {
            font-weight: 500;
            font-size: 15px;
            display: block;
            width: 100%;
            padding-bottom: 10px;
        }

        .text-hour {
            display: block;
            width: 100%;
            text-align: justify;
        }
    }
}

.modal-operation-hours {
    width: 100%;
    border-radius: 10px;
    padding-inline: 32px;
    z-index: 25;

    form {
        margin: 0;
        height: 100%;
        justify-content: flex-start;
        padding-inline: 6px;
    }

    .modal-header {
        font-weight: 500;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        padding-left: 16px;
        font-size: 16px;
    }

    .modal-content {
        padding: 16px;

        ion-item {
            ion-select {
                width: 100%;
            }
        }
    }

    .footer-buttons {
        display: flex;
        justify-content: center;
        column-gap: 16px;
        padding-bottom: 32px;

        ion-button {
            width: 100%;
        }

        .btn-cancel {
            --box-shadow: transparent;
        }
    }

}
