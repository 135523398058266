.card-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 16px;
    color: #121622;

    width: 100%;
    min-height: 50px;
    border-radius: 12px;
    background-color: #e3b424;

    ion-icon {
        font-size: 25px;
    }

    .close {
        cursor: pointer;
    }

    .banner-content {
        width: 100%;
        padding: 16px;

        .banner-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        .banner-description {
            text-align: justify;
            margin-top: 8px;
        }
    }
}

@media (max-width: 750px) {
    .card-banner {
        align-items: flex-start;

        .icon-info {
            display: none;
        }

        .close {
            margin-top: 12px;
        }
    }
}
