.popover-button {
    ion-item {
        --background: #18202D;
        transition: all 0.5s linear;
        color: white;

        .view-all {
            font-weight: 600;
            color: white;
        }
    }
    
    ion-item:hover {
        --background: #20b4ac;
        font-weight: 500;
        box-shadow: 0px 0px 5px 2px rgba(32, 180, 172, 0.25);
    }
}
