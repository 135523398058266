.field-filter-date {
    .date-picker {
        width: 300px;
        min-height: 37px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 4px 0;
        padding: 0 15px;
        border-radius: 5px;
        cursor: pointer;
        span {
            margin: 0 5px;
        }
    }

    .date-picker2 {
        width: 300px;
        margin-top: 6px;
        
        .mdc-text-field--filled:not(.mdc-text-field--disabled), .mdc-text-field--disabled.mdc-text-field--filled {
            border-radius: 5px;
            background: #20b4ac;
        }

        .date-input, .date-input input::placeholder, .mat-date-range-input-inner[disabled], .mat-date-range-input-separator, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input, .mdc-text-field--disabled .mdc-text-field__input {
            color: white !important;
            font-size: 14px;
        }

        .date-icon {
            button {
                color: white;
            }
        }

        .mat-mdc-form-field-flex {
            height: 40px;
        }

        .mat-mdc-form-field-infix {
            min-height: 37px;
            padding-top: 8px !important;
        }
        
        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
        }
    }
}

.mat-datepicker-content {
    background: #18202d;

    .date-picker-actions {
        background-color: #20b4ac !important;
        color: white !important;
    }
}

.mat-calendar-body-cell-content, .mat-calendar-table-header, 
.mat-calendar-body-label, .mat-mdc-button:not(:disabled), 
.mat-mdc-button.mat-unthemed, .mat-calendar-arrow, .mat-datepicker-toggle, 
.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content 
.mat-calendar-previous-button {
    color: #B5BCC6;
    --mdc-text-button-label-text-color: #B5BCC6;
    fill: #B5BCC6;
}

.mat-calendar-body-selected {
    background-color: #20b4ac;
    color: white !important;
}

.mat-calendar-body-in-range::before, .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: rgb(32, 180, 172, .2);
}

@media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background: rgb(32, 180, 172, .2);
    }
}


@media (max-width: 750px) {

    .date-picker {
        width: 100% !important;
    }
}