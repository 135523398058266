// .div-input {
    
//     ion-item {
//         --highlight-color-invalid: #374151;
//     }
// }

.item-input,
.item-select {
    --border-color: #374151 !important;
    --highlight-color-focused: #20b4ac;
    --highlight-color-valid: #20b4ac;
}

// ion-input {
//     --border-color: #374151 !important;
//     --placeholder-color: #B5BCC6 !important ;
// }

// //Text área (tela de feedback)
// ion-textarea {
//     border: none;
//     --placeholder-color: #B5BCC6;
// }

// .ion-textarea {
//     border:1px solid #20B4AC;
// }