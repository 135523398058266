.card-graph {
    background-color: #F8F9FB;

    .card-title {
        color: #131821;
    }

    .item {
        color: #4c5e64;
    }
    .description {

        ion-icon {
            color: #20b4ac;
        }
    }

    .chart-header {
        ion-icon {
            color: #20b4ac;
        }
    }
}

.card-graph-inline { 
    border: 1px solid rgba(88, 168, 247, 0.3);
    background: transparent;
}

.card-graph-login {
    background: rgba(248, 249, 251, 0.9);
}

.background-last-operations {
    background-color: #F8F9FB;
}

.item-statistic {
    background-color: #F8F9FB;

    .title-statistic {
        color: #131821;
    }
}

.card-tips {
    background: #F8F9FB;
}

.card-home:hover {
    box-shadow: 0px 2px 5px 0px rgba(32, 180, 172, 0.25);
    transition: all 0.5s;
}