.info-card-graph {
    padding-inline: 12px;
    height: 92px;

    .row-info-card {
        display: flex;
        justify-content: space-between;

        .info {
            margin-right: 16px;

            .description {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 12px;
                white-space: nowrap;
            }

            .value{ 
                font-size: 20px;
                font-weight: 500;
                white-space: nowrap;
            }
        }

        #graph-month, #graph-performance {
            width: 100%;
        }

        #graph-hit {
            width: 100%;
            top: -8px;
            position: relative;
            max-width: 100px;
        }

        #graph-average {
            width: 100%;
            top: -12px;
            max-width: 80px;
            position: relative;
        }

        ion-spinner {
            margin-right: 16px;
            position: relative;
            top: 6px;
        }
    }
    
}