.paginator {
    ion-fab-button {
        --background: #20b4ac;
        --box-shadow: none;
    }

    span {
        color: #4c5e64;
    }

    .selected {
        color: #131821;
    }
}
