ion-modal {
    app-plan-progress {
        padding: 32px 32px 0;
        color: white;
        overflow-y: scroll !important;

        &.ion-page {
            justify-content: flex-start;
        }

        .plan-progress{
            margin-top: 0;
            .plan-detail {
                grid-template-columns: 80% auto;

                .over-text.icon {
                    justify-content: flex-end;
                    img{margin-right: 0;}
                }
                .hide-mobile {
                    display: none;
                }
            }
        }
    }
}

app-plan-progress {
    .header-buttons {
        display: flex;
        justify-content: space-between;

        .title {
            display: block;
        }

        ion-icon {
            font-size: 24px;
            cursor: pointer;
        }

        ion-button {
            width: 250px;
        }
    }
    
    .plan-info {
        margin-top: 16px;

        .span-web {
            width: 530px;
        }
        span {
            margin-bottom: 5.6%;
            text-align: center;

            .plan-user-name {
                color: #20b4ac;
                font-weight: 600;
            }
        }
    }
    .plan-mobile {
        margin-top: 0;
        margin-bottom: 32px !important;
    }
    .plan-progress{
        margin-top: 32px;
        .progress-section {
            margin: 0 18vw;
        }

        .subtitle{
            margin-bottom: 25px;
        }

        .plan-detail {
            width: 100%;
            display: grid;
            grid-template-columns: 22% 22% 22% 22%;
            gap: 4%;
            font-weight: 500;

            .color-light {
                font-weight: 600;
                color: #FFFFFF;
            }
    
            &.table-title {
                font-size: 16px;
                font-weight: 700;
                color: #B5BCC6;
            }

            .icon {
                display: flex;
                align-items: center;
                img {
                    margin-right: 8px;
                }
            }
        }
    
        .log-plan {
            span {
                margin-bottom: 16px;
                display: block;
            }
        }
    }
    /*  EXPANSION PANEL  */
    .mat-expansion-panel {
        --mat-expansion-container-shape: 10px;  //border radius
        background-color: #172031; //background color

        &:not(:last-child):not(.mat-expanded) { //apply to rows not expanded and not last one
            border-bottom: 1px solid #585B62;
        }
    }

    @media (hover: none){
        .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
            background: #172031;
        }
    }

    @media (max-width: 750px) {
        .finish-plan-button {
            padding-bottom: 32px;
        }

        .plan-progress{
            margin-top: 0;
            .plan-detail {
                grid-template-columns: 80% auto;

                .over-text.icon {
                    justify-content: flex-end;
                    img{margin-right: 0;}
                }
                .hide-mobile {
                    display: none;
                }
            }
        }

        .mat-expansion-panel-header-title, .mat-content.mat-content-hide-toggle {
            margin-right: 0;
        }
    }
}
@media (max-width: 750px) {
    app-plan-progress {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}